import { ReactNode } from 'react';
import NextHead from 'next/head';
import { useTranslation } from 'src/lib/translations';

interface HeadProps {
  title?: string;
  subtitle: string;
  openGraphImage?: string;
  pageKeywords?: string;
  description?: string;
  children?: ReactNode;
}

const Head = (props: HeadProps) => {
  const { t } = useTranslation();

  const DEFAULT_DESCRIPTION = t('Head.subtitle.default');
  const DEFAULT_KEYWORDS = 'NFL Highlights, NFL NFT, NFL Collectibles';

  const subtitle = props.subtitle ? `${props.subtitle} | ` : '';
  const title = `${subtitle}${t('Head.title')}`;
  const keywords = props.pageKeywords
    ? `${props.pageKeywords}, ${DEFAULT_KEYWORDS}`
    : DEFAULT_KEYWORDS;
  const description = props.description || DEFAULT_DESCRIPTION;
  const ogImage =
    props.openGraphImage ??
    `${process.env.SITE_URL}/static/images/nfl-opengraph.jpg`;

  return (
    <NextHead>
      <title>{title}</title>

      <link rel="apple-touch-icon" href="/favicon/favicon-180x180.png" />
      <link
        rel="icon"
        sizes="any"
        type="image/png"
        href="/favicon/favicon.png"
      />
      <link
        rel="icon"
        sizes="any"
        type="image/svg+xml"
        href="/favicon/favicon.svg"
      />

      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/favicon.png" color="#000000" />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {/* Schema.org for Google */}
      <meta itemProp="name" content={t('Head.title')} />
      <meta itemProp="description" content={description} />

      {/* Open Graph general */}
      <meta name="og:type" content="website" />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={ogImage} />

      {/* Twitter Card */}
      <meta name="twitter:title" content={title} />

      {/* Other dynamic meta tags */}
      {props?.children}
    </NextHead>
  );
};

export default Head;
