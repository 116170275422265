import { Fragment } from 'react';
import Head from 'src/general/components/Head/Head';
import { ErrorPage } from 'src/general/components/ErrorPage/ErrorPage';


export default function ErrorPageHandler() {
  return (
    <Fragment>
      <Head subtitle="An error ocurred" />
      <ErrorPage title="An error ocurred" />
    </Fragment>
  );
}
